import React, { useState, useEffect, useContext, useRef } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoodIcon from "@material-ui/icons/Mood";
import MessageIcon from "@material-ui/icons/Message";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

import useQuickMessages from "../../hooks/useQuickMessages";

import Compressor from "compressorjs";
import LinearWithValueLabel from "./ProgressBarCustom";
import { Event } from "@material-ui/icons";
import ScheduleModal from "../ScheduleModal";
import EmoteIcon from "../../assets/icon/EmoteIcon";
import PaperClipIcon from "../../assets/icon/PaperClipIcon";
import DocumentTextIcon from "../../assets/icon/DocumentTextIcon";
import CalendarIcon from "../../assets/icon/CalendarIcon";
import CustomSwitch from "../SwitchCustom/SwitchCustom";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import ErrorIcon from "../../assets/icon/ErrorIcon";
import SendIcon from "../../assets/icon/SendIcon";
import SquareAddIcon from "../../assets/icon/SquareAddIcon";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    minHeight: 60,
  },

  newMessageBox: {
    background: theme.palette.background.default,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: theme.palette.inputMessage,
    fontSize: "1",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      marginRight: 5,
    },
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },

  placeholderText: {
    position: "absolute",
    left: 10,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.total,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  SendButton: {
    background: theme.palette.primary.main,
    height: 45,
    width: 55,
    padding: 0,
    minWidth: "10px !important",
    borderRadius: 10,
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 35,
      width: 40,
    },
  },

  quickMessagesBox: {
    position: "absolute",
    bottom: "70px",
    left: "8px",
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "auto",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
    background: theme.palette.background.default,
    padding: "10px",
  },
  quickMessageItem: {
    cursor: "pointer",
    padding: "8px",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
}));

const EmojiOptions = (props) => {
  const { disabled, showEmoji, setShowEmoji, handleAddEmoji, menu } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEmojiSelect = (emoji) => {
    handleAddEmoji(emoji);
    setShowEmoji(false);
  };

  const getEmojiPerLine = () => {
    if (isSmallScreen) return 8;
    if (isMediumScreen) return 12;
    return 16; 
  };

  return (
    <>
      <IconButton
        aria-label="emojiPicker"
        component="span"
        disabled={disabled}
        onClick={() => setShowEmoji((prevState) => !prevState)}
        style={isSmallScreen && !menu ? { display: "none" } : {}}
      >
        <EmoteIcon />
      </IconButton>
      {showEmoji ? (
        <div className={classes.emojiBox}>
          <Picker
            perLine={getEmojiPerLine()} 
            showPreview={false}
            showSkinTones={false}
            onSelect={handleEmojiSelect}
          />
        </div>
      ) : null}
    </>
  );
}
const QuickMessages = (props) => {
  const {
    disabled,
    showMessage,
    setShowMessage,
    handleAddQuickMessage,
    quickMessages,
    menu,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleQuickMessageSelect = (message) => {
    handleAddQuickMessage(message);
    setShowMessage(false); // Cierra el modal después de seleccionar un mensaje rápido
  };

  return (
    <>
      <IconButton
        aria-label="quickMessages"
        component="span"
        disabled={disabled}
        onClick={(e) => setShowMessage((prevState) => !prevState)}
        style={isSmallScreen & !menu ? { display: "none" } : {}}
      >
        <DocumentTextIcon />
      </IconButton>
      {showMessage ? (
        <div className={classes.quickMessagesBox}>
          {quickMessages.map((item) => (
            <div
              key={item.id}
              className={classes.quickMessageItem}
              onClick={() => handleQuickMessageSelect(item.message)}
            >
              {item.shortcode}: {truncateText(item.message, 94)}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

const SignSwitch = (props) => {
  const { setSignMessage, signMessage, menu, small, marginLeft } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <FormControlLabel
      style={
        isSmallScreen & !menu
          ? { display: "none" }
          : { marginLeft: 10, color: theme.palette.text.secondary }
      }
      label={i18n.t("messagesInput.signMessage")}
      labelPlacement="end"
      control={
        <CustomSwitch
          size={small}
          checked={signMessage}
          onChange={(e) => {
            setSignMessage(e.target.checked);
          }}
          name="showAllTickets"
          color="primary"
          marginTop={-1}
          marginLeft={marginLeft}
        />
      }
    />
  );
};

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const FileInput = (props) => {
  const { handleChangeMedias, disableOption, menu } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <input
        multiple
        type="file"
        id="upload-button"
        disabled={disableOption()}
        className={classes.uploadInput}
        onChange={handleChangeMedias}
      />
      <label htmlFor="upload-button">
        <IconButton
          aria-label="upload"
          component="span"
          disabled={disableOption()}
          style={isSmallScreen & !menu ? { display: "none" } : {}}
        >
          <PaperClipIcon />
        </IconButton>
      </label>
    </>
  );
};

const ScheduleEventButton = ({ contactId, menu, handleMenuClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.stopPropagation(); // Evita que el evento se propague
    handleMenuClose()
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="schedule-event"
        component="span"
        onClick={handleOpenModal}
        className={classes.scheduleButton}
        style={isSmallScreen && !menu ? { display: "none" } : {fontSize: 15, marginRight: theme.spacing(1)} }
      >
        <CalendarIcon className={classes.icon} />
       {isSmallScreen && <Typography style={{marginLeft: theme.spacing(1.8), color: theme.palette.text.secondary}}>Programar Mensajes</Typography> }  
      </IconButton>

      <ScheduleModal
        open={open}
        onClose={handleCloseModal}
        contactId={contactId}
      />
    </>
  );
};

const ActionButtons = (props) => {
  const {
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording,
  } = props;
  const classes = useStyles();
  if (recording) {
    return (
      <div className={classes.recorderWrapper}>
        <IconButton
          aria-label="cancelRecording"
          component="span"
          fontSize="large"
          disabled={loading}
          onClick={handleCancelAudio}
        >
          <ErrorIcon
            className={classes.cancelAudioIcon}
            width="24"
            height="24"
          />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.audioLoading} />
          </div>
        ) : (
          <RecordingTimer />
        )}

        <IconButton
          aria-label="sendRecordedAudio"
          component="span"
          onClick={handleUploadAudio}
          disabled={loading}
        >
          <SuccessIcon
            width="24"
            height="24"
            className={classes.sendAudioIcon}
          />
        </IconButton>
      </div>
    );
  } else {
    return (
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={handleStartRecording}
      >
        <MicIcon style={{ color: "grey" }} />
      </IconButton>
    );
  }
};

const CustomInput = (props) => {
  const {
    loading,
    inputRef,
    ticketStatus,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    handleInputPaste,
    disableOption,
  } = props;
  const classes = useStyles();
  const [quickMessages, setQuickMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const { list: listQuickMessages } = useQuickMessages();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map((m) => {
        let truncatedMessage = m.message;
        if (isString(truncatedMessage) && truncatedMessage.length > 35) {
          truncatedMessage = m.message.substring(0, 35) + "...";
        }
        return {
          value: m.message,
          label: `/${m.shortcode} - ${truncatedMessage}`,
        };
      });
      setQuickMessages(options);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 1
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const filteredOptions = quickMessages.filter(
        (m) => m.label.indexOf(inputMessage) > -1
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
  }, [inputMessage]);

  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  const setInputRef = (input) => {
    if (input) {
      input.disabled = loading;
      input.focus();
      inputRef.current = input;
    }
  };

  return (
    <div className={classes.messageInputWrapper}>
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        closeIcon={null}
        getOptionLabel={(option) => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value")) {
            setInputMessage(opt.value);
            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{ width: "100%" }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              disabled={disableOption() || loading}
              inputRef={setInputRef}
              multiline
              className={classes.messageInput}
              maxRows={5}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={i18n.t("messagesInput.placeholderOpen")}
            />
          );
        }}
      />
    </div>
  );
};

const MessageInputCustom = (props) => {
  const { ticketStatus, ticketId, contactId } = props;
  const classes = useStyles();
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [percentLoading, setPercentLoading] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickMessages, setQuickMessages] = useState([]);
  const [status, setstatus] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const { list: listQuickMessages } = useQuickMessages();

  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyingMessage]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  useEffect(() => {
    async function fetchData() {
      try {
        const companyId = localStorage.getItem("companyId");
        const messages = await listQuickMessages({
          companyId,
          userId: user.id,
        });
        setQuickMessages(messages);
      } catch (error) {
        console.log("Error al obtener la informacion del usuario");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.get("/tickets/" + ticketId);
        setstatus(data.status);
      } catch (error) {
        console.log("Error al obtener datos del ticket");
      }
    }
    fetchData();
  }, [ticketId]);

  const handleMenuOpen = (event) => {
    setShowEmoji(false);
    setShowMessage(false);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };
  const handleAddQuickMessage = (message) => {
    setInputMessage(message);
  };

  const handleOpenEmojiModal = () => {
    setShowEmoji(true);
    setShowMessage(false);
    handleMenuClose();
  };

  const handleOpenQuickMessageModal = () => {
    setShowMessage(true);
    setShowEmoji(false);
    handleMenuClose();
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }
  
    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
    handleMenuClose();
  };
  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);

    medias.forEach(async (media, idx) => {
      const file = media;

      if (!file) {
        return;
      }

      if (media?.type.split("/")[0] == "image") {
        new Compressor(file, {
          quality: 0.7,

          async success(media) {
            formData.append("medias", media);
            formData.append("body", media.name);
          },
          error(err) {
            alert("erro");
            console.log(err.message);
          },
        });
      } else {
        formData.append("medias", media);
        formData.append("body", media.name);
      }
    });

    setTimeout(async () => {
      try {
        await api
          .post(`/messages/${ticketId}`, formData, {
            onUploadProgress: (event) => {
              let progress = Math.round((event.loaded * 100) / event.total);
              setPercentLoading(progress);
              console.log(`A imagem  está ${progress}% carregada... `);
            },
          })
          .then((response) => {
            setLoading(false);
            setMedias([]);
            setPercentLoading(0);
            console.log(`A imagem á foi enviada para o servidor!`);
          })
          .catch((err) => {
            console.error(`Houve um problema ao realizar o upload da imagem.`);
            console.log(err);
          });
      } catch (err) {
        toastError(err);
      }
    }, 2000);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    inputRef.current.focus();
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const disableOption = () => {
    return loading || recording || ticketStatus !== "open";
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon />
        </IconButton>
      </div>
    );
  };

  if (medias.length > 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon />
        </IconButton>

        {loading ? (
          <div>
            {/*<CircularProgress className={classes.circleLoading} />*/}
            <LinearWithValueLabel progress={percentLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    );
  else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        {replyingMessage && renderReplyingMessage(replyingMessage)}
        {status === "open" && (
          <div className={classes.newMessageBox}>
            {isSmallScreen ? (
              <>
                <IconButton
                  aria-label="more"
                  aria-controls="icon-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <SquareAddIcon />
                </IconButton>
                <Menu
                  id="icon-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      minWidth: 900,
                      width: "100%",
                      height: "34vh",
                      backgroundColor: theme.palette.inputBackground,
                      display: "flex",
                      flexDirection: "column",
                      zIndex: 1300,
                      padding: 0,
                      marginLeft: -15,
                      marginTop: 15,
                      borderRadius: 0,
                      color: theme.palette.text.secondary,
                    },
                  }}
                >
                  <MenuItem onClick={handleOpenEmojiModal}>
                    <EmojiOptions
                      disabled={disableOption()}
                      handleAddEmoji={handleAddEmoji}
                      showEmoji={showEmoji}
                      setShowEmoji={setShowEmoji}
                      menu={true}
                    />{" "}
                    Emojis
                  </MenuItem>
                  <MenuItem>
                    <label htmlFor="upload-button">
                      <FileInput
                        disableOption={disableOption}
                        handleChangeMedias={handleChangeMedias}
                        menu={true}
                      />
                      Adjuntar archivos
                    </label>
                  </MenuItem>
                  <MenuItem onClick={handleOpenQuickMessageModal}>
                    <QuickMessages
                      disabled={disableOption()}
                      showMessage={showMessage}
                      setShowMessage={setShowMessage}
                      handleAddQuickMessage={handleAddQuickMessage}
                      quickMessages={quickMessages}
                      menu={true}
                    />{" "}
                    Mensajes rapidos
                  </MenuItem>
                  <MenuItem >
                    <ScheduleEventButton contactId={contactId} menu={true} handleMenuClose={handleMenuClose} />
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <SignSwitch
                      width={props.width}
                      setSignMessage={setSignMessage}
                      signMessage={signMessage}
                      menu={true}
                      small="small"
                      marginLeft= {-1}
                    />
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <></>
            )}
            <EmojiOptions
              disabled={disableOption()}
              handleAddEmoji={handleAddEmoji}
              showEmoji={showEmoji}
              setShowEmoji={(value) => {
                setShowEmoji(value);
                if (value) setShowMessage(false);
              }}
            />

            <FileInput
              disableOption={disableOption}
              handleChangeMedias={handleChangeMedias}
            />
            <QuickMessages
              disabled={disableOption()}
              showMessage={showMessage}
              setShowMessage={(value) => {
                setShowMessage(value);
                if (value) setShowEmoji(false);
              }}
              handleAddQuickMessage={handleAddQuickMessage}
              quickMessages={quickMessages}
            />
            <SignSwitch
              width={props.width}
              setSignMessage={setSignMessage}
              signMessage={signMessage}
              small=""
              marginLeft= {2}
            />

            <CustomInput
              loading={loading}
              inputRef={inputRef}
              ticketStatus={ticketStatus}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleSendMessage={handleSendMessage}
              handleInputPaste={handleInputPaste}
              disableOption={disableOption}
            />

            {inputMessage ? (
              <Button
                onClick={handleSendMessage}
                disabled={loading}
                variant="containedPrimary"
                className={classes.SendButton}
              >
                <SendIcon fill={theme.palette.text.main} />
              </Button>
            ) : (
              <ActionButtons
                inputMessage={inputMessage}
                loading={loading}
                recording={recording}
                ticketStatus={ticketStatus}
                handleSendMessage={handleSendMessage}
                handleCancelAudio={handleCancelAudio}
                handleUploadAudio={handleUploadAudio}
                handleStartRecording={handleStartRecording}
              />
            )}

            <ScheduleEventButton contactId={contactId} />
          </div>
        )}
      </Paper>
    );
  }
};

export default MessageInputCustom;
