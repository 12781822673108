const SquareAddIcon = ({
  fill = "#909090",
  width = "18",
  height = "18",
  strokeWidth = "1.5",
}) => {
  return (
<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 9.5H12" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 12.5V6.5" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V7.25C16.5 3.5 15 2 11.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default SquareAddIcon;
