import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  IconButton,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import CircleActiveIcon from "../../assets/icon/CircleActiveIcon";
import CircleIcon from "../../assets/icon/CircleIcon";
import CloseIcon from "../../assets/icon/CloseIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  modarlContainer: {
    background: theme.palette.tabHeaderBackground,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "72vh",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
    borderRadius: 10,
    padding: 10,
    width: 100,
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  btnWrapper: {
    position: "relative",
    borderRadius: 10,
    padding: 10,
    width: 100,
  },
  noHover: {
    minWidth: 0,
    paddingRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000014",
      },
      "&:hover fieldset": {
        borderColor: "#00000014",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000014",
      },
    },
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId, whatsApps }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta",
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const { user } = useContext(AuthContext);
  const totalConnection = user?.company?.totalConnection;
  console.log(totalConnection);
  const currentConnection = whatsApps.length;

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = { ...values, queueIds: selectedQueueIds };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        console.log(currentConnection);
        if (totalConnection >= currentConnection) {
          await api.post("/whatsapp", whatsappData);
          toast.success(i18n.t("whatsappModal.success"));
        } else {
          toast.error(
            "Has alcanzado el número máximo de conexiones. Compra una conexión adicional para continuar."
          );
        }
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle className={classes.modarlContainer}>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8, color: "#ff1744" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {loading ? (
          <DialogContent>
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <Formik
            initialValues={whatsApp}
            enableReinitialize={true}
            validationSchema={SessionSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveWhatsApp(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, touched, errors, isSubmitting, setFieldValue }) => (
              <Form>
                <DialogContent dividers className={classes.modarlContainer}>
                  <div className={classes.multFieldLine}>
                    <Grid spacing={2} container>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label={i18n.t("whatsappModal.form.name")}
                          autoFocus
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          className={classes.textField}
                        />
                      </Grid>

                      <Grid style={{ paddingTop: 18 }} item>
                        <Button
                          onClick={() =>
                            setFieldValue("isDefault", !values.isDefault)
                          }
                          disableRipple
                          disableFocusRipple
                          className={classes.noHover}
                        >
                          {values.isDefault ? (
                            <CircleActiveIcon />
                          ) : (
                            <CircleIcon />
                          )}
                        </Button>
                        {i18n.t("whatsappModal.form.default")}
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.greetingMessage")}
                      type="greetingMessage"
                      multiline
                      rows={4}
                      fullWidth
                      className={classes.textField}
                      name="greetingMessage"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.complationMessage")}
                      type="complationMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="complationMessage"
                      className={classes.textField}
                      error={
                        touched.complationMessage &&
                        Boolean(errors.complationMessage)
                      }
                      helperText={
                        touched.complationMessage && errors.complationMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.outOfHoursMessage")}
                      type="outOfHoursMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="outOfHoursMessage"
                      className={classes.textField}
                      error={
                        touched.outOfHoursMessage &&
                        Boolean(errors.outOfHoursMessage)
                      }
                      helperText={
                        touched.outOfHoursMessage && errors.outOfHoursMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.ratingMessage")}
                      type="ratingMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="ratingMessage"
                      className={classes.textField}
                      error={
                        touched.ratingMessage && Boolean(errors.ratingMessage)
                      }
                      helperText={touched.ratingMessage && errors.ratingMessage}
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.token")}
                      type="token"
                      fullWidth
                      name="token"
                      variant="outlined"
                      className={classes.textField}
                      margin="dense"
                    />
                  </div>
                  <QueueSelect
                    selectedQueueIds={selectedQueueIds}
                    onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                  />
                </DialogContent>
                <DialogActions className={classes.modarlContainer}>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("whatsappModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {whatsAppId
                      ? i18n.t("whatsappModal.buttons.okEdit")
                      : i18n.t("whatsappModal.buttons.okAdd")}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
