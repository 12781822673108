import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, InputAdornment, IconButton, Grid } from '@material-ui/core';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import VenttifyIcon from "../../assets/icon/VenttifyIcon.js";
import { Visibility, VisibilityOff, Email, LockOutlined } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { openApi } from "../../services/api";
import { useTheme } from "@material-ui/core/styles";
import { EmailIcon } from "../../assets/icon/EmailIcon.js";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "70%",
        marginTop: "40px",
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: 'bold',
    },
    input: {
        marginTop: "30px",
        '& .MuiInputBase-input': {
            '&::placeholder': {
                color: theme.palette.text.secondary,
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize:"13px"
                  },
            },
        }
    },
    submit: {
        fontWeight: "bold",
        fontSize: "16px",
        textTransform: 'none',
        marginBottom: theme.spacing(3),
        margin: theme.spacing(5, 0, 2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        color: '#1D1C18',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            backgroundColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        marginLeft: "5px",
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const EmailSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("Requerido"),
});

const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string().min(5, "Contraseña muy corta").max(50, "Contraseña muy larga").required("Requerido"),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Las contraseñas deben coincidir').required("Requerido"),
});

const ResetPasswordForm = () => {
    const { token } = useParams();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleEmailSubmit = async (values, { setSubmitting }) => {
        try {
            await openApi.post("/change-password-email", { email: values.email });
            toast.success("Se ha enviado un correo con instrucciones para cambiar la contraseña");
        } catch (error) {
            toast.error("Este usuario no existe.");
        } finally {
            setSubmitting(false);
        }
    };

    const handlePasswordSubmit = async (values, { setSubmitting }) => {
        try {
            await openApi.post("/change-password", { token, password: values.newPassword });
            toast.success("Contraseña cambiada correctamente");
            history.push("/login");
        } catch (error) {
            toast.error("Error al cambiar la contraseña.");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={classes.paper}>
            <div className={classes.logo}>
                <VenttifyIcon fill={theme.palette.primary.main} />
                <Typography component="p" variant="subtitle1" style={{ fontWeight: "bold", padding: "5px" }}>
                    Venttify
                </Typography>
            </div>
            <Typography component="h1" variant="h5" className={classes.title}>
                {token ? "Cambiar Contraseña" : "Recuperar Contraseña"}
            </Typography>
            <Formik
                initialValues={token ? { newPassword: '', confirmPassword: '' } : { email: '' }}
                validationSchema={token ? PasswordSchema : EmailSchema}
                onSubmit={token ? handlePasswordSubmit : handleEmailSubmit}
            >
                {({ errors, touched }) => (
                    <Form className={classes.form}>
                        {!token ? (
                            <Field
                                as={TextField}
                                variant="outlined"
                                fullWidth
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                className={classes.input}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                    placeholder: 'Ingresa tu correo electrónico'
                                }}
                            />
                        ) : (
                            <>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    name="newPassword"
                                    label="Nueva Contraseña"
                                    type={showNewPassword ? 'text' : 'password'}
                                    id="newPassword"
                                    error={touched.newPassword && Boolean(errors.newPassword)}
                                    helperText={touched.newPassword && errors.newPassword}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlined color='disabled'/>
                                            </InputAdornment>
                                        ),
                                        placeholder: 'Ingresa tu contraseña',
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirmar Contraseña"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlined color='disabled' />
                                            </InputAdornment>
                                        ),
                                        placeholder: 'Confirma tu contraseña',
                                    }}
                                />
                            </>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            {token ? "Cambiar Contraseña" : "Enviar Correo"}
                        </Button>

                        <Grid item >
                            Volver al
                            <RouterLink to="/login" className={classes.link} style={{ fontWeight:"bold",}}>
                                inicio de sesión
                            </RouterLink>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPasswordForm;