import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import EyeIcon from "../../assets/icon/EyeIcon";
import ErrorIcon from "../../assets/icon/ErrorIcon";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    padding: theme.spacing(2),
  },
  pendingTicket: {
    cursor: "unset",
  },
  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contactLastMessage: {
    paddingRight: 20,
    color: theme.palette.text.secondary,
  },
  badgeStyle: {
    color: "white",
    backgroundColor: theme.palette.success.main,
  },
  lastMessageTime: {
    justifySelf: "flex-end",
    color: theme.palette.text.secondary,
  },
  tag: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    borderRadius: "12px",
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  empresaTag: {
    backgroundColor: theme.palette.info.light,
  },
  canalTag: {
    backgroundColor: theme.palette.error.light,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));


const TicketListItem = ({ ticket, isSelected  }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  console.log(isSelected)

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAcepptTicket = async (ticket) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSelectTicket = (ticket) => {
    history.push(`/tickets/${ticket.uuid}`);
  };


  return (
    <React.Fragment key={ticket.id}>
      <ListItem
        dense
        button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={isSelected}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>

        <ListItemText
          primary={
            <div className={classes.contactNameWrapper}>
              <Typography component="span" variant="body2" color="textPrimary">
                {ticket.contact.name}
              </Typography>
              <div>
                <span className={clsx(classes.tag, classes.empresaTag)}>
                  Empresa Y
                </span>
                <span className={clsx(classes.tag, classes.canalTag)}>
                  Canal 5
                </span>
              </div>
            </div>
          }
          secondary={
            <div className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                variant="body2"
                color="textSecondary"
              >
                {ticket.lastMessage || "No hay mensajes aún."}
              </Typography>
              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime}
                  variant="body2"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date())
                    ? format(parseISO(ticket.updatedAt), "HH:mm")
                    : format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}
                </Typography>
              )}
            </div>
          }
        />
        <div className={classes.actions}>
          <IconButton size="small">
            <EyeIcon />
          </IconButton>
          <IconButton size="small" color="secondary">
            <ErrorIcon />
          </IconButton>
        </div>
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItem;