import React, { useState, useEffect, useContext } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { FormControl, Grid, IconButton, InputLabel, ListItemText, makeStyles, MenuItem, Select, Typography, useTheme } from "@material-ui/core";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/icon/CloseIcon";
import SearchIcon from "../../assets/icon/SearchIcon";
import DownArrow from "../../assets/icon/DownArrow";

const useStyles = makeStyles((theme) => ({
	root: {
	  '& .MuiDialog-paper': {
		backgroundColor: theme.palette.tabHeaderBackground,
		color: theme.palette.text.primary,
	  },
	},
	dialogTitle: {
	  display: 'flex',
	  justifyContent: 'space-between',
	  alignItems: 'center',
	},
	closeButton: {
		color: theme.palette.text.primary,
	},
	content: {
	  paddingTop: theme.spacing(2),
	},
	input: {
	  '& .MuiInputBase-input': {
		color: theme.palette.text.primary,
	  },
	  '& .MuiInput-underline:before': {
		borderBottomColor: 'rgba(255, 255, 255, 0.42)',
	  },
	  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderBottomColor: 'rgba(255, 255, 255, 0.87)',
	  },
	},
	select: {
		"&.MuiOutlinedInput-root": {
		  "& fieldset": {
			borderColor: "#00000014",
		  },
		  "&:hover fieldset": {
			borderColor: "#00000014",
		  },
		  "&.Mui-focused fieldset": {
			borderColor: "#00000014",
		  },
		},
	  },
	  chip: {
		margin: theme.spacing(0.5),
	  },
	  icon: {
		right: theme.spacing(1),
		position: "absolute",
		pointerEvents: "none",
	  },
	  menuItem: {
		padding: theme.spacing(1, 2),
	  },
	btnWrapper: {
		position: "relative",
		borderRadius: 10,
		padding: 10,
		width: 100,
	  },
  }));
  
  const filter = createFilterOptions({
	trim: true,
  });
  
  const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {
	const classes = useStyles();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState("");
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);

	const theme = useTheme();
  
	useEffect(() => {
	  if (initialContact?.id !== undefined) {
		setOptions([initialContact]);
		setSelectedContact(initialContact);
	  }
	}, [initialContact]);
  
	useEffect(() => {
	  if (!modalOpen || searchParam.length < 3) {
		setLoading(false);
		return;
	  }
	  setLoading(true);
	  const delayDebounceFn = setTimeout(() => {
		const fetchContacts = async () => {
		  try {
			const { data } = await api.get("contacts", {
			  params: { searchParam },
			});
			setOptions(data.contacts);
			setLoading(false);
		  } catch (err) {
			setLoading(false);
			toastError(err);
		  }
		};
  
		fetchContacts();
	  }, 500);
	  return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);
  
	const handleClose = () => {
	  onClose();
	  setSearchParam("");
	  setSelectedContact(null);
	};
  
	const handleSaveTicket = async contactId => {
	  if (!contactId) return;
	  if (selectedQueue === "" && user.profile !== 'admin') {
		toast.error("Seleccione un canal");
		return;
	  }
	  setLoading(true);
	  try {
		const queueId = selectedQueue !== "" ? selectedQueue : null;
		const { data: ticket } = await api.post("/tickets", {
		  contactId: contactId,
		  queueId,
		  userId: user.id,
		  status: "open",
		});
		onClose(ticket);
	  } catch (err) {
		toastError(err);
	  }
	  setLoading(false);
	};
  
	const handleSelectOption = (e, newValue) => {
	  if (newValue?.number) {
		setSelectedContact(newValue);
	  } else if (newValue?.name) {
		setNewContact({ name: newValue.name });
		setContactModalOpen(true);
	  }
	};
  
	const handleCloseContactModal = () => {
	  setContactModalOpen(false);
	};
  
	const handleAddNewContactTicket = contact => {
	  handleSaveTicket(contact.id);
	};
  
	const createAddContactOption = (filterOptions, params) => {
	  const filtered = filter(filterOptions, params);
  
	  if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
		filtered.push({
		  name: `${params.inputValue}`,
		});
	  }
  
	  return filtered;
	};
  
	const renderOption = option => {
	  if (option.number) {
		return `${option.name} - ${option.number}`;
	  } else {
		return `${i18n.t("newTicketModal.add")} ${option.name}`;
	  }
	};
  
	const renderOptionLabel = option => {
	  if (option.number) {
		return `${option.name} - ${option.number}`;
	  } else {
		return `${option.name}`;
	  }
	};
  
	return (
	  <>
		<ContactModal
		  open={contactModalOpen}
		  initialValues={newContact}
		  onClose={handleCloseContactModal}
		  onSave={handleAddNewContactTicket}
		/>
		<Dialog 
		  open={modalOpen} 
		  onClose={handleClose} 
		  maxWidth="xs"
		  fullWidth 
		  className={classes.root}
		>
		  <DialogTitle disableTypography className={classes.dialogTitle}>
			<Typography variant="h6">{i18n.t("newTicketModal.title")}</Typography>
			<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
				<CloseIcon />
			</IconButton>
		  </DialogTitle>
		  <DialogContent className={classes.content}>
			<Grid container spacing={2}>
			  {(initialContact === undefined || initialContact.id === undefined) && (
				<Grid item xs={12}>
				  <Autocomplete
					fullWidth
					options={options}
					loading={loading}
					clearOnBlur
					autoHighlight
					freeSolo
					clearOnEscape
					getOptionLabel={renderOptionLabel}
					renderOption={renderOption}
					filterOptions={createAddContactOption}
					onChange={(e, newValue) => handleSelectOption(e, newValue)}
					renderInput={(params) => (
					  <TextField
						{...params}
						label={i18n.t("newTicketModal.fieldLabel")}
						variant="outlined"
						autoFocus
						onChange={(e) => setSearchParam(e.target.value)}
						className={classes.input}
						onKeyPress={(e) => {
						  if (loading || !selectedContact) return;
						  else if (e.key === "Enter") {
							handleSaveTicket(selectedContact.id);
						  }
						}}
						InputProps={{
						  ...params.InputProps,
						  endAdornment: (
							<>
							  {loading ? (
								<CircularProgress color="inherit" size={20} />
							  ) : null}
							  {params.InputProps.endAdornment}
							</>
						  ),
						  startAdornment: (
							<div style ={{ marginTop:5,	marginLeft: 5}}>
								<SearchIcon fill={theme.palette.text.secondary} height={20} width={20} />
							</div>
						  )
						}}
					  />
					)}
				  />
				</Grid>
			  )}
			  <Grid item xs={12}>
			  <FormControl fullWidth variant="outlined">
      <InputLabel>Seleccione un departamento</InputLabel>
      <Select
        fullWidth
        value={selectedQueue}
        onChange={(e) => setSelectedQueue(e.target.value)}
        className={classes.select}
        renderValue={(selected) => {
          if (selected === "") {
            return "Seleccione un departamento";
          }
          const queue = user.queues.find((q) => q.id === selected);
          return (
            <Chip
              key={queue.id}
              label={queue.name}
              className={classes.chip}
              style={{ backgroundColor: queue.color }}
            />
          );
        }}
        IconComponent={() => (
          <div className={classes.icon}>
            <DownArrow fill={theme.palette.messageIcons} />
          </div>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {user.queues?.length > 0 &&
          user.queues.map((queue) => (
            <MenuItem key={queue.id} value={queue.id} className={classes.menuItem}>
              <ListItemText primary={queue.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
			  </Grid>
			</Grid>
		  </DialogContent>
		  <DialogActions>
			<Button
			  onClick={handleClose}
			  color="primary"
			  disabled={loading}
			  variant="outlined"
			  className={classes.btnWrapper}
			>
			  {i18n.t("newTicketModal.buttons.cancel")}
			</Button>
			<ButtonWithSpinner
			  variant="containedPrimary"
			  type="button"
			  disabled={!selectedContact}
			  onClick={() => handleSaveTicket(selectedContact.id)}
			  loading={loading}
			  className={classes.btnWrapper}
			  style={{color: "black"}}
			>
			  {i18n.t("newTicketModal.buttons.ok")}
			</ButtonWithSpinner>
		  </DialogActions>
		</Dialog>
	  </>
	);
  };
  
  export default NewTicketModal;